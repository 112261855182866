import { inter } from '../fonts/inter';

export default function GlobalFont() {
  return (
    <style jsx global>{`
      html {
        font-family: ${inter.style.fontFamily}, sans-serif;
      }
    `}</style>
  );
}
