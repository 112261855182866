import {
  CANCEL_CONNECT_SOCIAL,
  FAILURE_CONNECT_SOCIAL,
  REQUEST_CONNECT_SOCIAL,
  RESET_CONNECT_SOCIAL,
  SET_CONNECT_SOCIAL_CREDENTIALS,
  SET_CONNECT_SOCIAL_FACEBOOK_PAGES,
  SET_CONNECT_SOCIAL_GOOGLE_MY_BUSINESS_PAGES,
  SET_CONNECT_SOCIAL_INSTAGRAM_BUSINESS_PAGES,
  SET_CONNECT_SOCIAL_LINKEDIN_PAGES,
  SET_CONNECT_SOCIAL_LOADING,
  SET_CONNECT_SOCIAL_PAGE,
  SUCCESS_CONNECT_SOCIAL,
} from './actionTypes';
import {
  ConnectSocialAction,
  FacebookPageItem,
  LinkedinPageItem,
  SocialCredentials,
} from './types';

interface ConnectSocialState {
  platform?: string;
  loading: boolean;
  status: ConnectSocialStatus;
  page: string;
  credentials?: SocialCredentials;
  cssTrackingSource?: string;
  facebookPages: FacebookPageItem[];
  facebookPageSaved: boolean;
  linkedinPages: LinkedinPageItem[];
  showFailurePopup: boolean;
  isFrom: string;
  excludePlatforms: string[];
  onSuccess?: () => void;
}

export enum ConnectSocialStatus {
  Success,
  Failed,
  None,
  Canceled,
}

export const initialState: ConnectSocialState = {
  loading: false,
  status: ConnectSocialStatus.None,
  page: 'main',
  credentials: {
    req_id: '',
    username: '',
    password: '',
    code: '',
    message: '',
  },
  facebookPages: [],
  facebookPageSaved: false,
  linkedinPages: [],
  showFailurePopup: false,
  isFrom: 'none',
  excludePlatforms: [],
};

const connectSocialReducer = (
  state = initialState,
  action: ConnectSocialAction
) => {
  switch (action.type) {
    case RESET_CONNECT_SOCIAL:
      return initialState;
    case FAILURE_CONNECT_SOCIAL:
      return {
        ...state,
        error: action.payload.error,
        status: ConnectSocialStatus.Failed,
        loading: false,
      };

    case SUCCESS_CONNECT_SOCIAL:
      return {
        ...state,
        loading: false,
        status: ConnectSocialStatus.Success,
      };

    case REQUEST_CONNECT_SOCIAL:
      return {
        ...state,
        status: ConnectSocialStatus.None,
        platform: action.payload.platform,
        platformType: action.payload.platformType,
        page: action.payload.page,
        cssTrackingSource: action.payload.cssTrackingSource,
        showFailurePopup: action.payload.showFailurePopup,
        isFrom: action.payload.isFrom,
        excludePlatforms: action.payload.excludePlatforms,
        onSuccess: action.payload.onSuccess,
        closeAll: action.payload.closeAll,
        loading: true,
        error: '',
      };
    case SET_CONNECT_SOCIAL_LOADING:
      return { ...state, loading: action.payload.loading };
    case SET_CONNECT_SOCIAL_PAGE:
      return {
        ...state,
        page: action.payload.page,
        plan: action.payload.plan,
        loading: false,
      };
    case SET_CONNECT_SOCIAL_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload.credentials,
      };
    case SET_CONNECT_SOCIAL_FACEBOOK_PAGES:
      return {
        ...state,
        facebookPages: action.payload.facebookPages,
        platformType: action.payload.platformType,
      };
    case SET_CONNECT_SOCIAL_GOOGLE_MY_BUSINESS_PAGES:
      return {
        ...state,
        googleBizPages: action.payload.googleBizPages,
        platformType: action.payload.platformType,
      };
    case SET_CONNECT_SOCIAL_LINKEDIN_PAGES:
      return {
        ...state,
        linkedinPages: action.payload.linkedinPages,
      };
    case SET_CONNECT_SOCIAL_INSTAGRAM_BUSINESS_PAGES:
      return {
        ...state,
        instagramBusinessPages: action.payload.instagramBusinessPages,
      };
    case CANCEL_CONNECT_SOCIAL:
      return { ...state, status: ConnectSocialStatus.Canceled };
    default:
      return state;
  }
};

export default connectSocialReducer;
