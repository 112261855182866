import dayjs from '@/src/core/utils/dayjs';
import {
  IAdsType,
  IAudienceSizeStatus,
  ICreative,
  ICreativeDetails,
  ICreativeGoal,
  ICreativeTexts,
  ILanguage,
  ILocationType,
  IManualInfo,
  IManualLocationOption,
  IPackage,
  IResultType,
} from '@/types/smart-ads';
import { empty } from './helpers';
import { IBrokerage } from '@/types/brokerage';
import { IUser } from '@/types/user';

export const ADS_TYPE_LEAD_GEN: IAdsType = 'leadgen';
export const ADS_TYPE_REACH: IAdsType = 'reach';

export const GOAL_SELLERS: ICreativeGoal = 'sellers';
export const GOAL_BUYERS: ICreativeGoal = 'buyers';
export const GOAL_PRESALES: ICreativeGoal = 'presales';
export const GOAL_FORECAST: ICreativeGoal = 'forecast';
export const GOAL_BROKERAGE: ICreativeGoal = 'brokerage';
export const GOAL_LISTINGS: ICreativeGoal = 'listings';

export const LANGUAGE_ENGLISH: ILanguage = 'english';
export const LANGUAGE_CHINESE: ILanguage = 'chinese';
export const LANGUAGE_SPANISH: ILanguage = 'spanish';

export const CREATIVE_LIST_UNDER_X_CAMPAIGN: string = 'list-under-x';
export const CREATIVE_LIST_ACREAGE_CAMPAIGN: string = 'list-acreage';
export const CREATIVE_LIST_APARTMENT_CAMPAIGN: string = 'list-apartment';
export const CREATIVE_LIST_TOWNHOUSE_CAMPAIGN: string = 'list-townhouse';
export const CREATIVE_LIST_SEMI_DETACHED_CAMPAIGN: string =
  'list-semi-detached';
export const CREATIVE_DYNAMIC_HOME_WORTH_CAMPAIGN: string =
  'home-worth-dynamic';
export const CREATIVE_HOME_WORTH_CAMPAIGN: string = 'home-worth';
export const CREATIVE_DYNAMIC_JOIN_US_CAMPAIGN: string = 'join-us';

export const DYNAMIC_TEXTS_CREATIVES = [
  CREATIVE_DYNAMIC_HOME_WORTH_CAMPAIGN,
  CREATIVE_LIST_UNDER_X_CAMPAIGN,
  CREATIVE_LIST_ACREAGE_CAMPAIGN,
  CREATIVE_LIST_APARTMENT_CAMPAIGN,
  CREATIVE_LIST_TOWNHOUSE_CAMPAIGN,
  CREATIVE_LIST_SEMI_DETACHED_CAMPAIGN,
  CREATIVE_DYNAMIC_JOIN_US_CAMPAIGN,
];

export const LOCATION_TYPE_EVERYONE: ILocationType = 'home,recent';
export const LOCATION_TYPE_PEOPLE: ILocationType = 'home';

export const PACKAGES: IPackage[] = [
  { key: 1, dailyBudget: 10, duration: 7, recommended: false },
  { key: 2, dailyBudget: 15, duration: 7, recommended: true },
  { key: 3, dailyBudget: 10, duration: 30, recommended: false },
];

export const PACKAGES_BROKERAGES: IPackage[] = [
  { key: 1, dailyBudget: 20, duration: 7, recommended: false },
  { key: 2, dailyBudget: 30, duration: 7, recommended: true },
  { key: 3, dailyBudget: 20, duration: 30, recommended: false },
];

export const DEFAULT_CREATIVE_CAMPAIGN = CREATIVE_HOME_WORTH_CAMPAIGN;

export const DEFAULT_CREATIVE_STYLE = 2;

export const DEFAULT_LANGUAGE: ILanguage = LANGUAGE_ENGLISH;

export const DEFAULT_CREATIVE: ICreativeDetails = {
  goal: GOAL_SELLERS,
  campaign: DEFAULT_CREATIVE_CAMPAIGN,
  style: DEFAULT_CREATIVE_STYLE,
  language: DEFAULT_LANGUAGE,
};

export const DEFAULT_SELECTED_PACKAGE: number = 2;

export const DEFAULT_RADIUS: number = 25;

export const DEFAULT_DAILY_BUDGET: number = 10;

export const DEFAULT_AUTO_RERUN: boolean = true;

export const DEFAULT_AUTO_LEADS_FOLLOWUP: boolean = true;

export const DEFAULT_CREATIVE_PRICE: number = 1000000;

export const RESULT_TYPE: IResultType = 'reach';

export const DEFAULT_CREATIVE_TEXTS: ICreativeTexts = {
  text1: 'Wondering what your home will sell for?',
  text2: 'Click Below or Contact Me',
  text3: 'Get a Free & Accurate Valuation!',
  price: DEFAULT_CREATIVE_PRICE,
  city: '',
  brokerage_text1: '',
};

export const DEFAULT_MANUAL_INFO: IManualInfo = {
  isActive: false,
  isDefault: true,
  dailyBudget: DEFAULT_DAILY_BUDGET,
  startDate: dayjs().add(1, 'days').startOf('day').unix() * 1000,
  openStartDate: false,
  endDate: dayjs().add(8, 'days').startOf('day').unix() * 1000,
  openEndDate: false,
  locationType: LOCATION_TYPE_EVERYONE,
  radius: DEFAULT_RADIUS,
  userRegionName: null,
  userCityName: null,
  userRegionFBKey: null,
};

export const DEFAULT_MANUAL_LOCATION_OPTIONS: IManualLocationOption[] = [
  {
    locationType: LOCATION_TYPE_EVERYONE,
    radius: DEFAULT_RADIUS,
    isDefault: true,
  },
  {
    locationType: LOCATION_TYPE_PEOPLE,
    radius: DEFAULT_RADIUS,
    isDefault: true,
  },
];

export const DEFAULT_AUDIENCE_SIZE_STATUS: IAudienceSizeStatus = {
  everyone: { daily_reach: 0 },
  people: { daily_reach: 0 },
  customized: { daily_reach: 0 },
  loading: true,
};

export const getEstimatedLeads = (
  dailyBudget,
  durationDays
): { min: number; max: number } => {
  const totalBudget = dailyBudget * durationDays;
  const minLeads = Math.floor(totalBudget / 40);
  const maxLeads = Math.floor(totalBudget / 15);
  return { min: minLeads, max: maxLeads };
};

export const getEstimatedReach = (
  dailyBudget,
  durationDays,
  estimatedReach
): number => {
  const multiplier = dailyBudget * durationDays * 0.35;
  let reachSize = Math.round(estimatedReach * multiplier) / 1000;
  reachSize = Number(reachSize.toFixed(1));
  return reachSize;
};

export const isDynamicTextCreativeSelected = (
  creative: ICreativeDetails
): boolean => {
  return DYNAMIC_TEXTS_CREATIVES.includes(creative?.campaign);
};

export const formatCreativePrice = (price: number): string => {
  const priceString = price.toString();
  if (price < 1000000) {
    const mainDigit = priceString.substr(0, 3);
    return `$${mainDigit}K`;
  } else {
    const mainDigit = priceString.substr(0, priceString.length - 6);
    const decimalDigit = priceString.substr(mainDigit.length, 1);
    return `$${mainDigit}${decimalDigit != '0' ? '.' + decimalDigit : ''}M`;
  }
};

/**
 * get creatives with specific condition out of all creatives
 * if style or language not be null, only that specific style
 * and/or language will be filtered
 *
 * @param allCreatives
 * @param goal
 * @param campaign
 * @param style
 * @param language
 * @return ICreative[]
 */
export const getSpecificCreatives = (
  allCreatives: ICreative[],
  goal: ICreativeGoal,
  campaign: string = null,
  style: number = null,
  language: ILanguage = null
): ICreative[] => {
  return allCreatives.filter((creative) => {
    if (creative.goal !== goal) {
      return false;
    }

    if (campaign !== null && creative.campaign != campaign) {
      return false;
    }

    if (style !== null && creative.style != style) {
      return false;
    }

    if (language !== null && creative.language !== language) {
      return false;
    }

    return true;
  });
};

/**
 * get specific brokerage creatives
 * @param allCreatives
 * @param brokerage
 * @return ICreative[]
 */
export const getSpecificBrokerageCreatives = (
  allCreatives: ICreative[],
  brokerage: IBrokerage
): ICreative[] => {
  return allCreatives.filter((creative) => {
    if (creative.goal === GOAL_BROKERAGE && !empty(creative.brokerage_id)) {
      return [brokerage.id, brokerage.parent_id].includes(
        creative.brokerage_id
      );
    }

    return false;
  });
};

/**
 * Format 10 digits phone number
 * from XXXXXXXXXX to XXX-XXX-XXXX or
 * from +XXXXXXXXXXX to +X-XXX-XXX-XXXX
 * @param text
 * @return string
 */
export const formatPhoneNumber = (text: string): string => {
  // 7788398040 => 778-839-8040
  const pattern_without_country_code = /^\d{10}$/gi;
  if (pattern_without_country_code.exec(text)) {
    return text.slice(0, 3) + '-' + text.slice(3, 6) + '-' + text.slice(6);
  }

  // +17788398040 => +1-778-839-8040
  const pattern_with_country_code = /^\+1\d{10}$/gi;
  if (pattern_with_country_code.exec(text)) {
    return (
      text.slice(0, 2) +
      '-' +
      text.slice(2, 5) +
      '-' +
      text.slice(5, 8) +
      '-' +
      text.slice(8)
    );
  }

  return text;
};

export const getPackage = (
  user: IUser,
  selectedCreative: ICreativeDetails
): IPackage[] => {
  if (user.brokerage_admin && selectedCreative?.goal === GOAL_BROKERAGE) {
    return PACKAGES_BROKERAGES;
  } else {
    return PACKAGES;
  }
};
