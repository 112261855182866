export function capitalize(str) {
  if (empty(str)) return '';

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function ucwords(str) {
  if (empty(str)) return '';

  return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
}

export function kebabCaseToCamelCase(str) {
  if (empty(str)) return '';

  return str.toLowerCase().replace(/-\w/g, function (letter) {
    return letter.replace(/-/, '').toUpperCase();
  });
}

export function randomNumberBounded(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function formatNumber(num: number | string, digits = 2): string {
  let finalNumber = num ?? 0;

  if (typeof finalNumber === 'string') {
    finalNumber = +finalNumber;
    finalNumber = !isFinite(finalNumber) ? 0 : finalNumber;
  }

  return finalNumber.toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

export function empty(data, strict = true) {
  if (typeof data === 'string' && data.length === 0) {
    return true;
  }
  if (typeof data === 'number' && data === 0 && strict) {
    return true;
  }
  if (typeof data == 'boolean' && data === false && strict) {
    return true;
  }
  if (typeof data == 'undefined' || data === null) {
    return true;
  }
  if (Array.isArray(data)) {
    return data.length === 0;
  } else if (typeof data == 'object') {
    let count = 0;
    for (let i in data) {
      if (data.hasOwnProperty(i)) {
        count++;
      }
    }
    return count === 0;
  }

  return false;
}

export function numberWithCommas(number) {
  if (number == null) {
    return null;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function abstractName(
  fullName: string,
  limit: number = undefined
): string {
  const nameParts = fullName.toString().split(' ', limit);
  let abstractName = '';
  nameParts.forEach((part) => (abstractName += part.substr(0, 1)));
  return abstractName;
}

export function getUserSeparatedFullname(fullName: string): {
  firstName: string;
  lastName: string;
} {
  let separatedNames = {
    firstName: fullName,
    lastName: '',
  };

  const nameArray = fullName.split(' ');
  nameArray.map((name, index) => {
    if (index === 0) {
      separatedNames.firstName = name;
    } else {
      separatedNames.lastName += name;
    }

    if (index !== 0 && index !== nameArray.length - 1) {
      separatedNames.lastName += ' ';
    }
  });

  return separatedNames;
}
