import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function useStylesPreLoader() {
  const router = useRouter();

  useEffect(() => {
    const applyPreloadStylesheetLinks = () => {
      try {
        const preloadStylesheetLinks =
          document.querySelectorAll<HTMLLinkElement>(
            "link[rel='preload'][as='style']"
          );
        preloadStylesheetLinks.forEach((preloadLink) => {
          const existingStylesheetLink =
            document.querySelector<HTMLLinkElement>(
              `link[rel='stylesheet'][href='${preloadLink.href}']`
            );

          if (!existingStylesheetLink) {
            const stylesheetLink = document.createElement('link');
            stylesheetLink.rel = 'stylesheet';
            stylesheetLink.href = preloadLink.href;
            document.head.appendChild(stylesheetLink);
          }
        });
      } catch (error) {
        //  ignore the error
      }
    };

    const handleRouteChangeComplete = () => {
      applyPreloadStylesheetLinks();
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);
}
