export const RESET_CONNECT_SOCIAL = 'RESET_CONNECT_SOCIAL';
export const REQUEST_CONNECT_SOCIAL = 'REQUEST_CONNECT_SOCIAL';
export const FAILURE_CONNECT_SOCIAL = 'FAILURE_CONNECT_SOCIAL';
export const CANCEL_CONNECT_SOCIAL = 'CANCEL_CONNECT_SOCIAL';
export const SUCCESS_CONNECT_SOCIAL = 'SUCCESS_CONNECT_SOCIAL';

export const SET_CONNECT_SOCIAL_PAGE = 'SET_CONNECT_SOCIAL_PAGE';
export const SET_CONNECT_SOCIAL_CREDENTIALS = 'SET_CONNECT_SOCIAL_CREDENTIALS';
export const SET_CONNECT_SOCIAL_LOADING = 'SET_CONNECT_SOCIAL_LOADING';

export const SET_CONNECT_SOCIAL_GOOGLE_MY_BUSINESS_PAGES =
  'SET_CONNECT_SOCIAL_GOOGLE_MY_BUSINESS_PAGES';

export const SET_CONNECT_SOCIAL_FACEBOOK_PAGES =
  'SET_CONNECT_SOCIAL_FACEBOOK_PAGES';

export const SET_CONNECT_SOCIAL_LINKEDIN_PAGES =
  'SET_CONNECT_SOCIAL_LINKEDIN_PAGES';

export const SET_CONNECT_SOCIAL_INSTAGRAM_BUSINESS_PAGES =
  'SET_CONNECT_SOCIAL_INSTAGRAM_BUSINESS_PAGES';
