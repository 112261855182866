import type { QueryClientConfig } from '@tanstack/react-query';

export const reactQueryConfigs = {
  staleTime: 1000 * 60 * 2,
  cacheTime: 1000 * 60 * 3,
};

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
};
