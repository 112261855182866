import {
  FAILURE_GET_SMART_ADS_AUDIENCE_SIZE,
  FAILURE_GET_SMART_ADS_BASIC_INFO,
  REQUEST_GET_SMART_ADS_AUDIENCE_SIZE,
  REQUEST_GET_SMART_ADS_BASIC_INFO,
  RESET_SMART_ADS_AUDIENCE_SIZE,
  SUCCESS_GET_SMART_ADS_AUDIENCE_SIZE,
  SUCCESS_GET_SMART_ADS_BASIC_INFO,
  UPDATE_SMART_ADS_AUTO_LEADS_FOLLOWUP,
  UPDATE_SMART_ADS_AUTO_RERUN,
  UPDATE_SMART_ADS_CREATIVE,
  UPDATE_SMART_ADS_CREATIVE_TEXTS,
  UPDATE_SMART_ADS_LISTING,
  UPDATE_SMART_ADS_MANUAL_INFO,
  UPDATE_SMART_ADS_MANUAL_LOCATION_OPTIONS,
  UPDATE_SMART_ADS_PAGE_NUMBER,
  UPDATE_SMART_ADS_SELECTED_PACKAGE,
  UPDATE_SMART_ADS_USER_INFO,
} from './actionTypes';
import { ProfileAction, SmartAdsState } from './types';
import {
  DEFAULT_AUDIENCE_SIZE_STATUS,
  DEFAULT_AUTO_LEADS_FOLLOWUP,
  DEFAULT_AUTO_RERUN,
  DEFAULT_CREATIVE,
  DEFAULT_CREATIVE_TEXTS,
  DEFAULT_MANUAL_INFO,
  DEFAULT_MANUAL_LOCATION_OPTIONS,
  DEFAULT_RADIUS,
  DEFAULT_SELECTED_PACKAGE,
} from '@/core/smart-ads';

export const initialState: SmartAdsState = {
  basicInfo: {
    userRegionName: null,
    userCityName: null,
    userRegionFBKey: 0,
    locationRadius: DEFAULT_RADIUS,
    credit: 0,
    creatives: [],
  },
  loading: false,
  error: null,
  creative: DEFAULT_CREATIVE,
  userInfo: {
    photo: null,
    companyLogo: null,
    name: '',
    text1: '',
    text2: '',
    text3: '',
  },
  pageNumber: 1,
  selectedPackage: DEFAULT_SELECTED_PACKAGE,
  manualInfo: DEFAULT_MANUAL_INFO,
  autoRerun: DEFAULT_AUTO_RERUN,
  autoLeadsFollowup: DEFAULT_AUTO_LEADS_FOLLOWUP,
  creativeTexts: DEFAULT_CREATIVE_TEXTS,
  manualLocationOptions: DEFAULT_MANUAL_LOCATION_OPTIONS,
  audienceSizeStatus: DEFAULT_AUDIENCE_SIZE_STATUS,
  audienceSize: null,
  audienceSizeType: null,
  listing: null,
};

const smartAdsReducer = (state = initialState, action: ProfileAction) => {
  const payload = action.payload;
  switch (action.type) {
    case FAILURE_GET_SMART_ADS_BASIC_INFO:
      return {
        ...state,
        error: payload?.error || null,
        loading: false,
      };

    case SUCCESS_GET_SMART_ADS_BASIC_INFO:
      return {
        ...state,
        basicInfo: payload.basicInfo,
        manualInfo: {
          ...state.manualInfo,
          userRegionName: payload.basicInfo.userRegionName,
          userCityName: payload.basicInfo.userCityName,
          userRegionFBKey: payload.basicInfo.userRegionFBKey,
        },
        error: null,
        loading: false,
      };

    case REQUEST_GET_SMART_ADS_BASIC_INFO:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case UPDATE_SMART_ADS_CREATIVE:
      return {
        ...state,
        creative: payload.creative,
        listing: null,
      };

    case UPDATE_SMART_ADS_USER_INFO:
      return {
        ...state,
        userInfo: payload.userInfo,
      };

    case UPDATE_SMART_ADS_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: payload.pageNumber,
      };

    case UPDATE_SMART_ADS_SELECTED_PACKAGE:
      return {
        ...state,
        selectedPackage: payload.selectedPackage,
      };

    case UPDATE_SMART_ADS_MANUAL_INFO:
      return {
        ...state,
        manualInfo: payload.manualInfo,
      };

    case UPDATE_SMART_ADS_AUTO_RERUN:
      return {
        ...state,
        autoRerun: payload.autoRerun,
      };

    case UPDATE_SMART_ADS_AUTO_LEADS_FOLLOWUP:
      return {
        ...state,
        autoLeadsFollowup: payload.autoLeadsFollowup,
      };

    case UPDATE_SMART_ADS_CREATIVE_TEXTS:
      return {
        ...state,
        creativeTexts: payload.creativeTexts,
      };

    case UPDATE_SMART_ADS_MANUAL_LOCATION_OPTIONS:
      return {
        ...state,
        manualLocationOptions: payload.manualLocationOptions,
      };

    case REQUEST_GET_SMART_ADS_AUDIENCE_SIZE:
      return {
        ...state,
        error: null,
        audienceSizeStatus: {
          ...state.audienceSizeStatus,
          loading: true,
        },
      };

    case SUCCESS_GET_SMART_ADS_AUDIENCE_SIZE:
      return {
        ...state,
        error: null,
        audienceSizeStatus: {
          ...state.audienceSizeStatus,
          [payload.audienceSizeType]: payload.audienceSize,
          loading: false,
        },
      };

    case RESET_SMART_ADS_AUDIENCE_SIZE:
      return {
        ...state,
        error: null,
        audienceSizeStatus: DEFAULT_AUDIENCE_SIZE_STATUS,
      };

    case FAILURE_GET_SMART_ADS_AUDIENCE_SIZE:
      return {
        ...state,
        error: payload?.error || null,
        audienceSizeStatus: {
          ...state.audienceSizeStatus,
          loading: true,
        },
      };

    case UPDATE_SMART_ADS_LISTING:
      return {
        ...state,
        creative: null,
        listing: payload.listing,
      };

    default:
      return state;
  }
};

export default smartAdsReducer;
