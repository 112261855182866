export const REQUEST_GET_CRM_JOURNEYS_LIST = 'REQUEST_GET_CRM_JOURNEYS_LIST';
export const FAILURE_GET_CRM_JOURNEYS_LIST = 'FAILURE_GET_CRM_JOURNEYS_LIST';
export const SUCCESS_GET_CRM_JOURNEYS_LIST = 'SUCCESS_GET_CRM_JOURNEYS_LIST';

export const REQUEST_GET_CRM_JOURNEY_LEADS_LIST =
  'REQUEST_GET_CRM_JOURNEY_LEADS_LIST';
export const FAILURE_GET_CRM_JOURNEY_LEADS_LIST =
  'FAILURE_GET_CRM_JOURNEY_LEADS_LIST';
export const SUCCESS_GET_CRM_JOURNEY_LEADS_LIST =
  'SUCCESS_GET_CRM_JOURNEY_LEADS_LIST';
