import type { PopupManagerAction, PopupManagerState } from './types';
import * as actionsTypes from './actionTypes';

export const initialState: PopupManagerState = {
  popups: [],
  platform: '',
  isDefaultVideo: true,
  page: 'none',
  regionName: '',
  freePlan: { source: '' },
  iframePopup: { src: null, title: '' },
  postId: '',
};

const push = (state, action) => {
  return [...state.popups, action.payload?.name];
};

const handlers = {
  OPEN_POPUP: (state, action) => ({
    ...state,
    ...action.payload,
    popups: push(state, action),
  }),
  [actionsTypes.CLOSE_CURRENT_POPUP]: (state) => {
    const newPopups = [...state.popups];
    newPopups.pop();

    return {
      ...state,
      popups: newPopups,
      page: initialState.page,
      pageSection: initialState.pageSection,
    };
  },
  [actionsTypes.CLOSE_POPUP]: (state, action) => {
    const newPopups = state.popups.filter(
      (popupId) => popupId !== action.payload.popupId
    );

    const dynamicUpdates = Object.keys(initialState).reduce((acc, key) => {
      acc[key] =
        action.payload.popupId === key ? initialState[key] : state[key];
      return acc;
    }, {});

    return {
      ...state,
      ...dynamicUpdates,
      popups: newPopups,
      page: initialState.page,
      pageSection: initialState.pageSection,
    };
  },
  [actionsTypes.CLOSE_ALL_POPUP]: () => initialState,
  [actionsTypes.SET_DEFAULT_VIDEO]: (state, action) => ({
    ...state,
    isDefaultVideo: action.payload.isDefaultVideo,
  }),
  [actionsTypes.SET_SCREEN]: (state, action) => ({
    ...state,
    screen: action.payload.screen,
  }),
  [actionsTypes.UPDATE_POPUP_DATA]: (state, action) => ({
    ...state,
    [action.payload.dataKey]: action.payload[action.payload.dataKey],
  }),
};

const popupManagerReducer = (
  state = initialState,
  action: PopupManagerAction
) => {
  if (action.type in handlers) {
    return handlers[action.type](state, action);
  } else if (action.type.startsWith('OPEN_')) {
    return handlers.OPEN_POPUP(state, action);
  } else {
    return state;
  }
};

export default popupManagerReducer;
