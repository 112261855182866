export const getDeviceType = async () => {
  if (typeof navigator === 'undefined') return 'ssr';
  const { default: isWebview } = await import('is-ua-webview');
  if (isWebview(navigator.userAgent)) {
    const { isAndroid, isIOS } = await import('react-device-detect');
    if (isIOS) {
      return 'ios';
    }
    if (isAndroid) {
      return 'android';
    }
    return 'mobile';
  }
  return 'browser';
};
