import {
  FAILURE_GET_POST_ANALYTICS,
  REQUEST_GET_POST_ANALYTICS,
  SUCCESS_GET_POST_ANALYTICS,
} from './actionTypes';
import { PostAnalyticsState, ProfileAction } from './types';

export const initialState: PostAnalyticsState = {
  pagination: { total: 0, current: 0, limit: 5 },
  posts: [],
  loading: false,
  error: null,
};

const postAnalyticsReducer = (state = initialState, action: ProfileAction) => {
  const payload = action.payload;
  switch (action.type) {
    case FAILURE_GET_POST_ANALYTICS:
      return {
        ...state,
        error: payload?.error || null,
        loading: false,
      };

    case SUCCESS_GET_POST_ANALYTICS:
      return {
        ...state,
        posts: payload.posts,
        pagination: payload.pagination,
        loading: false,
      };

    case REQUEST_GET_POST_ANALYTICS:
      return {
        ...state,
        posts: [],
        error: null,
        loading: true,
      };

    default:
      return state;
  }
};

export default postAnalyticsReducer;
