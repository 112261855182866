export enum Socials {
  Facebook = 'facebook',
  Facebook2 = 'facebook_2',
  FacebookProfile = 'facebook-profile',
  Twitter = 'twitter',
  Linkedin = 'linkedin',
  Instagram = 'instagram',
  InstagramBusiness = 'instagram_business',
  Tiktok = 'tiktok',
  Youtube = 'youtube',
  Wordpress = 'wordpress',
  Google_my_business = 'google_my_business',
  All = 'all',
}

export type SocialMetadata = {
  slug: Socials;
  icon: string;
  title: string;
};

export type ShareSocialMetadata = SocialMetadata & {
  iconNative: string;
};
