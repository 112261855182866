import { FAILURE_SHARE, REQUEST_SHARE, SUCCESS_SHARE } from './actionTypes';
import { ProfileAction, ShareState } from './types';

export const initialState: ShareState = {
  loading: false,
  error: '',
};

const shareReducer = (state = initialState, action: ProfileAction) => {
  const payload = action.payload;
  switch (action.type) {
    case FAILURE_SHARE:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };

    case SUCCESS_SHARE:
      return {
        ...state,
        link: payload.link,
        queued: payload.queued,
        loading: false,
      };

    case REQUEST_SHARE:
      return {
        ...state,
        loading: true,
        error: '',
      };

    default:
      return state;
  }
};

export default shareReducer;
