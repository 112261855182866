import { empty } from './helpers';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { ParsedUrlQuery } from 'querystring';
import { createDateFromDay } from '@/src/shared/utils/date';
import { callPostApi } from '@/src/core/utils/api';

export interface IUTM {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_content: string | null;
  utm_term: string | null;
  is_sending?: boolean;
}

export interface IUTMTemporary {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_content: string | null;
  utm_term: string | null;
}

export default class UTM {
  static readonly COOKIE_NAME: string = 'user-utm-v2';
  static readonly COOKIE_NAME_TEMPORARY: string = 'user-utm-temporary';

  static readonly EXPIRE_DAYS: number = 14;

  static DEFAULT_UTM: IUTM = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    utm_term: null,
    is_sending: false,
  };

  /**
   * This function parse the URL and store UTMs in user's cookie
   * @param parameters
   */
  static parseURL(parameters: ParsedUrlQuery): void {
    try {
      let utm = this.extractUTMs(parameters);

      if (utm.hasUTM) {
        setCookie(UTM.COOKIE_NAME, JSON.stringify({ ...utm.userUTM }), {
          expires: createDateFromDay(UTM.EXPIRE_DAYS),
        });
      }
    } catch (error) {
      console.warn('Error parsing UTM parameters');
    }
  }

  static extractUTMs(parameters: ParsedUrlQuery): {
    hasUTM: boolean;
    userUTM: IUTM;
  } {
    let userUTM = UTM.DEFAULT_UTM;

    let hasUTM = false;

    for (const utm in userUTM) {
      if (!empty(parameters[utm])) {
        hasUTM = true;
        userUTM[utm] = Array.isArray(parameters[utm])
          ? parameters[utm][0]
          : parameters[utm];
      }
    }

    return {
      hasUTM,
      userUTM,
    };
  }

  /**
   * Get User's UTMs
   */
  static getUTMs(): IUTM {
    try {
      const uTMS = getCookie(UTM.COOKIE_NAME);
      if (uTMS != null) {
        if (typeof uTMS === 'string') {
          return JSON.parse(uTMS);
        } else {
          return JSON.parse(JSON.stringify(uTMS)) as IUTM;
        }
      }
    } catch {
      return UTM.DEFAULT_UTM;
    }

    return UTM.DEFAULT_UTM;
  }

  static getTemporaryUTMs(): IUTMTemporary | null {
    const UTMs = getCookie(UTM.COOKIE_NAME_TEMPORARY);
    if (UTMs) {
      const parsed_utms: IUTMTemporary =
        UTMs === 'string'
          ? JSON.parse(UTMs)
          : (JSON.parse(JSON.stringify(UTMs)) as IUTMTemporary);
      if (Object.values(parsed_utms).some((value) => value)) {
        return parsed_utms;
      }
    }

    return null;
  }

  static updateIsSending(utms: IUTM, newIsSending: boolean): void {
    utms.is_sending = newIsSending;
    setCookie(UTM.COOKIE_NAME, JSON.stringify({ ...utms }), {
      expires: createDateFromDay(UTM.EXPIRE_DAYS),
    });
  }

  static storeUTMsTemporarily(): void {
    const utms = UTM.getUTMs();
    setCookie(
      UTM.COOKIE_NAME_TEMPORARY,
      JSON.stringify({
        utm_source: utms.utm_source,
        utm_medium: utms.utm_medium,
        utm_campaign: utms.utm_medium,
        utm_content: utms.utm_content,
        utm_term: utms.utm_term,
      })
    );
  }

  static removeUTMs(): void {
    deleteCookie(UTM.COOKIE_NAME);
  }

  static async sendUTMs(): Promise<boolean> {
    const utms = UTM.getUTMs();

    try {
      if (
        utms &&
        !utms.is_sending &&
        Object.values(utms).some((value) => value)
      ) {
        UTM.updateIsSending(utms, true);
        const response = await callPostApi('/tracking/utms', { utms });
        if (response.data.status === 'success') {
          UTM.storeUTMsTemporarily();
          UTM.removeUTMs();
          return true;
        } else {
          UTM.updateIsSending(utms, false);
        }
      }
    } catch (e) {
      console.log('Error sending UTMs:', e);
      UTM.updateIsSending(utms, false);
    }

    return false;
  }
}
