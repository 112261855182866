import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useEventProvider } from '@/helpers/event-context';

type Props = {
  pageName: string;
  analyticData: Record<string, unknown>;
  analyticConfigs?: {
    disablePageViewEvent?: boolean;
    includeQueryInPageLocation?: boolean;
  };
};

export default function PageViewEventListener({
  pageName,
  analyticData,
  analyticConfigs,
}: Props) {
  const router = useRouter();
  const { sendEvent } = useEventProvider();

  useEffect(() => {
    if (pageName && !analyticConfigs?.disablePageViewEvent) {
      sendEvent('page_view', null, {
        page_location: analyticConfigs?.includeQueryInPageLocation
          ? router.asPath
          : window.location.pathname,
        page_name: pageName,
        ...analyticData,
      });
    }
  }, [pageName]);

  return null;
}
