import { callPostApi, getGaClientId } from '@/src/core/utils/api';
import { getDeviceType } from '@/src/core/utils/deviceType';
import { gtagConfigs } from '@/src/core/constants/gtagConfigs';
import TagManager from 'react-gtm-module';
import { getVisitorToken } from '@/src/core/utils/visitorToken';
import { isEmptyString } from '@/src/shared/utils/string';

const callCallbackIfDefined = (callback: () => void) => {
  if (callback) callback();
};

export class AnalyticApis {
  /** @deprecated */
  static trackUser = async (action = '', value = '', callback?: () => void) => {
    try {
      let data = {
        user_type: 'user',
        action: action,
        value: value,
      };

      await callPostApi('/tracking/track', data);
      callCallbackIfDefined(callback);
    } catch (ex) {
      callCallbackIfDefined(callback);
    }
  };

  /** @deprecated */
  static trackVisitor = async (visitor_token = '', action = '') => {
    try {
      let data = {
        user_type: 'visitor',
        visitor_token: visitor_token,
        action: action,
      };

      await callPostApi('/tracking/track', data);
    } catch (ex) {
      // ex
    }
  };

  static sendGtagEvent = async ({
    event,
    data = {},
    authorized,
  }: {
    event: string;
    data: Record<string, unknown>;
    authorized?: boolean;
  }) => {
    try {
      window.dataLayer = window.dataLayer || [];
      const payload = { ...data };
      payload['device-type'] = await getDeviceType();
      if (!authorized) {
        await getVisitorToken();
      }
      TagManager.dataLayer({
        dataLayer: {
          event,
          ...payload,
        },
      });
    } catch (err) {
      console.warn('[Gtag]: Problem sending gtag event');
    }
  };

  static gaEvent = async (
    event: string,
    otherData: object = {},
    callback?: () => void,
    notAuthorized?: boolean
  ) => {
    try {
      window.dataLayer = window.dataLayer || [];
      otherData['device-type'] = await getDeviceType();
      if (notAuthorized) {
        await getVisitorToken();
      }
      TagManager.dataLayer({
        dataLayer: {
          event: event,
          ...otherData,
        },
      });
      if (!notAuthorized) {
        const isGtagLoaded = !isEmptyString(await getGaClientId());
        if (isGtagLoaded) {
          gtag('get', gtagConfigs.trackerId, 'client_id', async (clientID) => {
            await this.sendEvent(event, otherData, clientID as string);
            callCallbackIfDefined(callback);
          });
        } else {
          await this.sendEvent(event, otherData);
          callCallbackIfDefined(callback);
        }
      } else {
        await this.sendEvent(event, otherData);
        callCallbackIfDefined(callback);
      }
    } catch (ex) {
      callCallbackIfDefined(callback);
    }
  };

  static sendEvent = async (
    event: string,
    otherData: object = {},
    clientId?: string
  ) => {
    try {
      await callPostApi('/tracking/event', {
        event_name: event,
        payload: JSON.stringify(otherData),
        client_id: clientId,
      });
    } catch (e) {}
  };
}
