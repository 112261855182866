import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux';
import connectSocialReducer from './connect-social/reducer';
import filterVideoReducer from './filter-videos/reducer';
import popupManagerReducer from './popup-manager/reducer';
import postReducer from './post/reducer';
import profileReducer from './profile/reducer';
import shareReducer from './share/reducer';
import tryRoomvuVideoReducer from './tryRoomvuVideo/reducer';
import typeReducer from './type/reducer';
import userReducer from './user/reducer';
import userRegisterReducer from './userRegister/reducer';
import videoReducer from './video/reducer';
import publicVideoListReducer from './publicVideoList/reducer';
import videoSvgOverlayReducer from './videoSvgOverlay/reducer';
import calculateTotalReducer from './totals/reducer';
import recommendedVideoReducer from './recommended-videos/reducer';
import postAnalyticsReducer from './post-analytics/reducer';
import smartAdsReducer from './smart-ads/reducer';
import emailMarketingReducer from './email-marketing/reducer';
import calendarPostsReducer from './calendar/reducer';
import detectDeviceReducer from './detectDevice/slice';
import videoCreatorPopupReducer from './videoCreatorPopup/slice';
import crmReducer from '@/redux/crm/reducer';
import contentFactoryReducer from '@/redux/content-factory/reducer';

const combinedReducer = combineReducers({
  video: videoReducer,
  popupManager: popupManagerReducer,
  tryRoomvuVideo: tryRoomvuVideoReducer,
  userRegister: userRegisterReducer,
  videoSvgOverlay: videoSvgOverlayReducer,
  profile: profileReducer,
  publicVideoList: publicVideoListReducer,
  user: userReducer,
  connectSocial: connectSocialReducer,
  post: postReducer,
  share: shareReducer,
  filterVideo: filterVideoReducer,
  type: typeReducer,
  calculateTotals: calculateTotalReducer,
  recommendedVideo: recommendedVideoReducer,
  postAnalytics: postAnalyticsReducer,
  smartAds: smartAdsReducer,
  emailMarketing: emailMarketingReducer,
  calendarPosts: calendarPostsReducer,
  crm: crmReducer,
  contentFactory: contentFactoryReducer,
  detectDevice: detectDeviceReducer,
  videoCreatorPopup: videoCreatorPopupReducer,
});

const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    return {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
  } else {
    return combinedReducer(state, action);
  }
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
