import React, { useState } from 'react';
import {
  Hydrate,
  QueryClientProvider,
  type HydrateProps,
  QueryClient,
} from '@tanstack/react-query';
import { queryClientConfig } from './configs';
// import dynamic from 'next/dynamic';

// interface ReactQueryDevtoolsProps {
//   initialIsOpen?: boolean;
//   position?: 'top' | 'bottom' | 'left' | 'right';
//   buttonPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
// }

// const ReactQueryDevtools = dynamic<ReactQueryDevtoolsProps>(() =>
//   import('@tanstack/react-query-devtools').then((res) => res.ReactQueryDevtools)
// );

type Props = {
  children: React.ReactNode;
  dehydratedState: HydrateProps['state'];
};

export function ReactQueryProvider({ children, dehydratedState }: Props) {
  const [queryClient] = useState(() => new QueryClient(queryClientConfig));
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        {children}
        {/* {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )} */}
      </Hydrate>
    </QueryClientProvider>
  );
}
