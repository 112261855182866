export default {
  websiteBaseUrl: 'https://www.roomvu.com',
  apiBaseUrl: 'https://www.roomvu.com/api',
  dashboardBaseUrl: 'https://www.roomvu.com/dashboard',
  imgBaseUrl: 'https://img.roomvu.com',
  useProxyForApiCall: false,
  gtm: {
    id: 'GTM-N8QQRLP',
    anonymousId: 'GTM-TMPCMQX',
  },
  auth: {
    loginUrl: 'https://www.roomvu.com/login',
    loginApiUrl: '/user/login',
    userAuthenticationUrl: '/user/get',
  },
  googleCaptcha: {
    siteKey: '6LeuuJcUAAAAAJtpfdUpKvjxk62bbz3KCNVfGkuz',
  },
  webEngage: {
    licenseCode: '82617805',
  },
  intrack: {
    IntrackAppKey: 'AAAAFQ',
    IntrackAuthKey: '2aa36c89-4a99-4ee7-af77-3f55d126f1b9',
    IntrackPublicKey:
      'BFAZ3Y6_DsD59Km4rSNTZneDoXk3SCCA-5kQpjNeCnE3BZOih_5qFfq7GdPoGOd-uiN39nnuCm4H6tFagFfMe1I=',
  },
  intercom: {
    IntercomAppID: 'vm09smau',
  },
  clarity: {
    projectId: 'l4sg1hdci9',
  },
  facebook: {
    appId: '629792100977483',
  },
  sentry: {
    DSN: 'https://0f2b2605c4334568a8e46d435cc7f6f1@sentry.roomvu.com/5',
    environment: 'production',
  },
  stripe: {
    key: 'pk_live_IAgqhpYJDVyC18JAWuWJc7dP',
  },
};
