import type { AxiosResponse } from 'axios';
import type { Dayjs } from 'dayjs';
import { callGetApi } from '@/src/core/utils/api';
import type { AppThunkDispatch, AppThunkResult } from '../types';
import type { CalendarState } from './types';

export enum CalendarActions {
  REQUEST_CALENDAR_POST = 'REQUEST_CALENDAR_POST',
  FAILURE_CALENDAR_POST = 'FAILURE_CALENDAR_POST',
  SUCCESS_CALENDAR_POST = 'SUCCESS_CALENDAR_POST',
  SET_CALENDAR_VIEW_DATE = 'SET_CALENDAR_VIEW_DATE',
  SET_FROM_EMBED_PAGE = 'SET_FROM_EMBED_PAGE',
}

export type IDayStatus = 'past-day' | 'post-day' | 'today';

export type CalendarDate = ICalendarWeek[] | ICalendarDay[];

const request = () => ({
  type: CalendarActions.REQUEST_CALENDAR_POST,
});

export const setFromEmbedPage = (fromEmbedPage: boolean) => ({
  type: CalendarActions.SET_FROM_EMBED_PAGE,
  payload: {
    fromEmbedPage,
  },
});

export const success = (
  posts: ICalendarDayPostsHolder[],
  calendarUpdating: boolean
) => ({
  type: CalendarActions.SUCCESS_CALENDAR_POST,
  payload: {
    posts,
    calendarUpdating,
  },
});

export const failure = (error: string) => ({
  type: CalendarActions.FAILURE_CALENDAR_POST,
  payload: {
    error,
  },
});

export const getCalendarPostsAction =
  (
    startDate: Dayjs,
    endDate: Dayjs,
    date_categorized = true,
    hasReload: boolean = true
  ): AppThunkResult =>
  async (dispatch: AppThunkDispatch) => {
    if (hasReload) dispatch(request());
    try {
      const response: AxiosResponse<{
        data: ICalendarDayPostsHolder[];
        calendar_updating: boolean;
      }> = await callGetApi('/calendar/index', {
        start: startDate.format('YYYY-MM-DD HH:mm:ss'),
        end: endDate.format('YYYY-MM-DD HH:mm:ss'),
        date_categorized,
      });
      const posts = response.data.data;
      const calendarUpdating = response.data.calendar_updating;
      dispatch(success(posts, calendarUpdating));
    } catch (e) {
      dispatch(failure('Failed to post'));
    }
  };

export const setCalendarViewDate = (viewDate: CalendarState['viewDate']) => ({
  type: CalendarActions.SET_CALENDAR_VIEW_DATE,
  payload: viewDate,
});

export const goToNextPeriod =
  (): AppThunkResult => async (dispatch: AppThunkDispatch, getState) => {
    const state = getState();
    const { selectCalendarPeriod, selectCalendarViewDate } = await import(
      './selectors'
    );
    const period = selectCalendarPeriod(state);
    const { default: dayjs } = await import('@/src/core/utils/dayjs');
    const viewDate = dayjs(selectCalendarViewDate(state));
    let newViewDate: Dayjs = null;
    switch (period) {
      case '14days':
        newViewDate = viewDate.add(14, 'days');
        break;
      case '7days':
        newViewDate = viewDate.add(7, 'days');
        break;
      case '1-month':
        newViewDate = viewDate.add(1, 'month').startOf('month');
        break;
    }
    dispatch(setCalendarViewDate(newViewDate?.format()));
    const { Dates } = await import('@/src/containers/Calendar/utils/date');
    const [startDate, endDate] = Dates(newViewDate, period);
    dispatch(getCalendarPostsAction(startDate, endDate));
  };

export const goToPreviousPeriod =
  (): AppThunkResult => async (dispatch: AppThunkDispatch, getState) => {
    const state = getState();
    const { selectCalendarPeriod, selectCalendarViewDate } = await import(
      './selectors'
    );
    const period = selectCalendarPeriod(state);
    const { default: dayjs } = await import('@/src/core/utils/dayjs');
    const viewDate = dayjs(selectCalendarViewDate(state));
    let newViewDate: Dayjs = null;
    switch (period) {
      case '14days':
        newViewDate = viewDate.subtract(14, 'days');
        break;
      case '7days':
        newViewDate = viewDate.subtract(7, 'days');
        break;
      case '1-month':
        newViewDate = viewDate.subtract(1, 'month').startOf('month');
        break;
    }
    dispatch(setCalendarViewDate(newViewDate?.format()));
    const { Dates } = await import('@/src/containers/Calendar/utils/date');
    const [startDate, endDate] = Dates(newViewDate, period);
    dispatch(getCalendarPostsAction(startDate, endDate));
  };
