import {
  LOG_OUT,
  SET_AUTH_STATUS,
  SET_LOGIN_STATUS,
  SET_USER_INFO,
  SET_USER_STATUS,
  SET_USER_TOKEN,
} from './actionTypes';
import { UserState } from './types';

export const initialState: UserState = {
  status: 'UNKNOWN',
  loginStatus: 'NOT_STARTED',
  loginError: null,
  authStatus: 'NOT_STARTED',
  authError: null,
  info: null,
  token: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SET_AUTH_STATUS:
      return {
        ...state,
        authStatus: action.payload.status,
        authError: action.payload.data?.errorMessage || null,
      };
    case SET_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload.status,
        loginError: action.payload.data?.errorMessage || null,
      };
    case SET_USER_TOKEN:
      return {
        ...state,
        token: action.payload,
        loginStatus: 'LOGGED_IN',
        loginError: null,
        status: 'LOGGED_IN',
      };
    case SET_USER_INFO:
      return {
        ...state,
        info: action.payload,
        authStatus: 'AUTHENTICATED',
        authError: null,
      };
    case LOG_OUT:
      return {
        ...initialState,
        status: 'NOT_LOGGED_IN',
      };
    default:
      return state;
  }
};

export default userReducer;
