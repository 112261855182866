import {
  REQUEST_GET_CRM_JOURNEYS_LIST,
  SUCCESS_GET_CRM_JOURNEYS_LIST,
  FAILURE_GET_CRM_JOURNEYS_LIST,
  FAILURE_GET_CRM_JOURNEY_LEADS_LIST,
  SUCCESS_GET_CRM_JOURNEY_LEADS_LIST,
  REQUEST_GET_CRM_JOURNEY_LEADS_LIST,
} from './actionTypes';
import { ProfileAction, CrmState } from './types';
import { DEFAULT_JOURNEYS } from '@/core/crm';

export const initialState: CrmState = {
  journeysList: DEFAULT_JOURNEYS,
  journeyLeadsList: [],
  journeyLeadsListStatus: {
    journey_id: null,
    loading: false,
    error: '',
  },
  loading: false,
  error: null,
};

const crmReducer = (state = initialState, action: ProfileAction) => {
  const payload = action.payload;
  switch (action.type) {
    case FAILURE_GET_CRM_JOURNEYS_LIST:
      return {
        ...state,
        error: payload?.error || null,
        loading: false,
      };

    case SUCCESS_GET_CRM_JOURNEYS_LIST:
      return {
        ...state,
        journeysList: payload.journeysList,
        error: null,
        loading: false,
      };

    case REQUEST_GET_CRM_JOURNEYS_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case FAILURE_GET_CRM_JOURNEY_LEADS_LIST:
      return {
        ...state,
        journeyLeadsListStatus: payload.journeyLeadsListStatus,
      };

    case SUCCESS_GET_CRM_JOURNEY_LEADS_LIST:
      return {
        ...state,
        journeyLeadsList: payload.journeyLeadsList,
        journeyLeadsListStatus: payload.journeyLeadsListStatus,
      };

    case REQUEST_GET_CRM_JOURNEY_LEADS_LIST:
      return {
        ...state,
        journeyLeadsList: [],
        journeyLeadsListStatus: {
          ...state.journeyLeadsListStatus,
          loading: true,
          error: '',
        },
      };

    default:
      return state;
  }
};

export default crmReducer;
