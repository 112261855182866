import { IUser } from '@/types/user';
import { UpgradePlans } from '@/types/subscription';

export function isPremiumPlan(subscription: IUser['subscription']) {
  return subscription === UpgradePlans.premium;
}

export function isPlatinumPlan(subscription: IUser['subscription']) {
  return subscription === UpgradePlans.platinum;
}

export function isBasicPlan(subscription: IUser['subscription']) {
  return subscription === UpgradePlans.basic;
}
