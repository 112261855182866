import { TryRoomvuVideoState } from '../../types/tryRoomvuVideo';
import {
  FAILURE_TRY_ROOMVU_VIDEO,
  REQUEST_TRY_ROOMVU_VIDEO,
  SUCCESS_TRY_ROOMVU_VIDEO,
} from './actionTypes';
import { VideoAction } from './types';

export const initialState: TryRoomvuVideoState = {
  data: null,
  loading: false,
  error: '',
};

const tryRoomvuVideoReducer = (state = initialState, action: VideoAction) => {
  const payload = action.payload;

  switch (action.type) {
    case FAILURE_TRY_ROOMVU_VIDEO:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };

    case SUCCESS_TRY_ROOMVU_VIDEO:
      return {
        ...state,
        data: payload?.data,
        loading: false,
      };

    case REQUEST_TRY_ROOMVU_VIDEO:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default tryRoomvuVideoReducer;
