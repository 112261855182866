import { VideoListState } from '../../types/video';
import {
  FAILURE_PUBLIC_VIDEO_LIST,
  REQUEST_PUBLIC_VIDEO_LIST,
  SUCCESS_PUBLIC_VIDEO_LIST,
} from './actionTypes';
import { PublicVideoListAction } from './types';

export const initialState: VideoListState = {
  lists: [],
  loading: false,
  error: '',
};

const publicVideoListReducer = (
  state = initialState,
  action: PublicVideoListAction
) => {
  const payload = action.payload;

  switch (action.type) {
    case FAILURE_PUBLIC_VIDEO_LIST:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };

    case SUCCESS_PUBLIC_VIDEO_LIST:
      return {
        ...state,
        lists: payload?.lists,
        loading: false,
      };

    case REQUEST_PUBLIC_VIDEO_LIST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default publicVideoListReducer;
