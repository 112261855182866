import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  isMobile,
  isDesktop,
  isMobileOnly,
  getSelectorsByUserAgent,
  isIOS,
} from 'react-device-detect';
import { DetectDeviceState } from './types';

const initialState: DetectDeviceState = {
  isDesktop: isDesktop ?? false,
  isMobile: isMobile ?? false,
  isMobileOnly: isMobileOnly ?? false,
  isIOS: isIOS ?? false,
};

const detectDeviceSlice = createSlice({
  name: 'detectDevice',
  initialState,
  reducers: {
    updateWithUserAgent: (state, action: PayloadAction<string>) => {
      const selector = getSelectorsByUserAgent(action.payload);
      state.isMobile = selector?.isMobile ?? false;
      state.isDesktop = selector?.isDesktop ?? false;
      state.isMobileOnly = selector?.isMobileOnly ?? false;
      state.deviceType = selector?.deviceType;
      state.isIOS = selector?.isIOS;
    },
  },
});

export const { updateWithUserAgent } = detectDeviceSlice.actions;
export default detectDeviceSlice.reducer;
