import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Steps, VideoCreatorPopupState } from './types';

const initialState: VideoCreatorPopupState = {
  currentStep: Steps.SELECT_TEMPLATE_COLOR,
};

const videoCreatorPopupSlice = createSlice({
  name: 'videoCreatorPopup',
  initialState,
  reducers: {
    resetCurrentStep: (state) => {
      state.currentStep = initialState.currentStep;
    },
    setCurrentStep: (
      state,
      action: PayloadAction<VideoCreatorPopupState['currentStep']>
    ) => {
      state.currentStep = action.payload;
    },
    goToNextStep: (state) => {
      if (state.currentStep + 1 !== Steps.SHARE_SAVE) {
        state.currentStep = state.currentStep + 1;
      }
    },
    goToPrevStep: (state) => {
      if (state.currentStep - 1 > -1) {
        state.currentStep = state.currentStep - 1;
      }
    },
  },
});

export const { setCurrentStep, goToPrevStep, resetCurrentStep, goToNextStep } =
  videoCreatorPopupSlice.actions;
export default videoCreatorPopupSlice.reducer;
