import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AnalyticApis } from '@/pages/api/analytics';
import { selectUserInfo } from '@/redux/user/selectors';
import { Pages } from '@/types/pages';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';
import UTM from '@/core/UTM';

interface ContextType {
  sendEvent: (
    event_name: string,
    callback?: () => void,
    extra?: Record<string, unknown>
  ) => void;
  sendGtagEvent: (args: {
    event: string;
    data: Record<string, unknown>;
  }) => void;
  getPageName: () => Pages | string;
}

const Context = createContext<ContextType>({
  sendEvent: () => {},
  sendGtagEvent: () => null,
  getPageName: () => 'none',
});

type Props = {
  children: React.ReactNode;
  pageName: string;
};

export default function EventContextProvider({ children, pageName }: Props) {
  const user = useSelector(selectUserInfo);
  const isMobile = useIsMobile();

  const sendEvent: ContextType['sendEvent'] = (event_name, callback, extra) => {
    const data = {
      page_location: window.location.pathname,
      page_name: pageName || 'none',
      is_mobile: isMobile,
      utms: UTM.getTemporaryUTMs() || user?.activeUTMs,
      ...extra,
    };
    void AnalyticApis.gaEvent(event_name, data, callback, !user);
    if (user) {
      UTM.sendUTMs().then((success) => {});
    }
  };

  const sendGtagEvent: ContextType['sendGtagEvent'] = ({ data, event }) => {
    AnalyticApis.sendGtagEvent({
      event,
      data: {
        page_location: window.location.pathname,
        source: pageName || 'none',
        is_mobile: isMobile,
        ...data,
      },
      authorized: user != null,
    });
  };

  const getPageName: ContextType['getPageName'] = () => pageName || 'none';

  return (
    <Context.Provider value={{ sendEvent, getPageName, sendGtagEvent }}>
      {children}
    </Context.Provider>
  );
}

export function useEventProvider() {
  return useContext(Context);
}
