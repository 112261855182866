import {
  FAILURE_RECOMMENDED_VIDEO,
  REQUEST_RECOMMENDED_VIDEO,
  SUCCESS_RECOMMENDED_VIDEO,
} from './actionTypes';
import { VideoAction } from './types';

export const initialState = {
  list: [],
  loading: false,
  error: '',
};

const recommendedVideoReducer = (state = initialState, action: VideoAction) => {
  const payload = action.payload;

  switch (action.type) {
    case FAILURE_RECOMMENDED_VIDEO:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };

    case SUCCESS_RECOMMENDED_VIDEO:
      return {
        ...state,
        list: payload?.list,
        loading: false,
      };

    case REQUEST_RECOMMENDED_VIDEO:
      return {
        ...state,
        list: [],
        loading: true,
      };

    default:
      return state;
  }
};

export default recommendedVideoReducer;
