import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUserToken } from '@/redux/user/selectors';
import { isEmptyString } from '@/src/shared/utils/string';

export default function ApiProvider() {
  const token = useSelector(selectUserToken);

  useEffect(() => {
    const updateToken = async () => {
      const { default: axios } = await import('axios');
      axios.defaults.headers.common['token'] = token;
    };
    const clearToken = async () => {
      const { default: axios } = await import('axios');
      axios.defaults.headers.common['token'] = null;
    };

    try {
      if (!isEmptyString(token)) {
        void updateToken();
      } else {
        void clearToken();
      }
    } catch (error) {
      void clearToken();
      //   ignore the error
    }
  }, [token]);

  return null;
}
