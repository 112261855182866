import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/redux/user/selectors';
import { useRouter } from 'next/router';
import UTM from '@/core/UTM';
import PageViewEventListener from '@/src/core/components/PageViewEventListener';
import NextNProgress from 'nextjs-progressbar';

type Props = {
  pageName: string;
  analyticData: Record<string, unknown>;
  analyticConfigs?: {
    disablePageViewEvent?: boolean;
    includeQueryInPageLocation?: boolean;
  };
};

function RouterEvents({ pageName, analyticData, analyticConfigs }: Props) {
  const user = useSelector(selectUserInfo);
  const router = useRouter();

  useEffect(() => {
    UTM.parseURL(router.query);
  }, [router]);

  return (
    <>
      <NextNProgress color="#326df7" />
      <PageViewEventListener
        pageName={pageName}
        analyticData={analyticData}
        analyticConfigs={analyticConfigs}
      />
    </>
  );
}

export default RouterEvents;
