import config from './config';

export default {
  defaultTitle: 'Hyper-Local Real Estate Ready-Made Videos | Convert Leads | roomvu',
  description: 'Helping Real Estate Agents, Loan Officers, Realtors, and Mortgage Brokerages to establish trust through tailored real estate websites, personalized videos, and advanced lead capturing tools. Convert real estate audience to leads with roomvu profile.',
  canonical: 'https://www.roomvu.com/',
  additionalLinkTags: [
    {
      rel: 'icon',
      href: `${config.imgBaseUrl}/favicon.ico`,
    },
  ],
  openGraph: {
    type: 'website',
    site_name: 'Roomvu',
    url: 'https://www.roomvu.com',
    title: 'Hyper-Local Real Estate Ready-Made Videos | Convert Leads | roomvu',
    description: 'Helping Real Estate Agents, Loan Officers, Realtors, and Mortgage Brokerages to establish trust through tailored real estate websites, personalized videos, and advanced lead capturing tools. Convert real estate audience to leads with roomvu profile.',
    images: [
      {
        url: 'https://dofimomuk6s4.cloudfront.net/files/th-home-page.jpg',
        width: 1200,
        height: 670,
        alt: 'roomvu',
      },
    ],
  },
  additionalMetaTags: [
    // Twitter Card
    {property: 'twitter:title', content: "Hyper-Local Real Estate Ready-Made Videos | Convert Leads | roomvu"},
    {
      property: 'twitter:description',
      content: "Helping Real Estate Agents, Loan Officers, Realtors, and Mortgage Brokerages to establish trust through tailored real estate websites, personalized videos, and advanced lead capturing tools. Convert real estate audience to leads with roomvu profile."
    },
    {property: 'twitter:image', content: 'https://dofimomuk6s4.cloudfront.net/files/th-home-page.jpg'},
  ],
  twitter: {
    cardType: 'summary_large_image',
  }
}
