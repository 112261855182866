export const CONTENT_FACTORY_SET_CONTENT_TYPE =
  'CONTENT_FACTORY_SET_CONTENT_TYPE';
export const CONTENT_FACTORY_SET_ZIPCODES = 'CONTENT_FACTORY_SET_ZIPCODES';
export const CONTENT_FACTORY_SET_LOCATION = 'CONTENT_FACTORY_SET_LOCATION';
export const CONTENT_FACTORY_SET_STEP = 'CONTENT_FACTORY_SET_STEP';
export const CONTENT_FACTORY_GET_AVAILABLE_CONTENTS =
  'CONTENT_FACTORY_GET_AVAILABLE_CONTENTS';
export const CONTENT_FACTORY_SET_LOADING = 'CONTENT_FACTORY_SET_LOADING';
export const CONTENT_FACTORY_FAILED = 'CONTENT_FACTORY_FAILED';
export const CONTENT_FACTORY_SET_AVAILABLE_CONTENTS_LOADING =
  'CONTENT_FACTORY_SET_AVAILABLE_CONTENTS_LOADING';
export const CONTENT_FACTORY_SET_AVAILABLE_CONTENTS =
  'CONTENT_FACTORY_SET_AVAILABLE_CONTENTS';
export const CONTENT_FACTORY_SET_TEMPLATE = 'CONTENT_FACTORY_SET_TEMPLATE';
export const CONTENT_FACTORY_SET_TEXT_FOR_VOICE =
  'CONTENT_FACTORY_SET_TEXT_FOR_VOICE';
export const CONTENT_FACTORY_SET_VOICE = 'CONTENT_FACTORY_SET_VOICE';
export const CONTENT_FACTORY_SET_HAS_VOICE_OVER =
  'CONTENT_FACTORY_SET_HAS_VOICE_OVER';
export const CONTENT_FACTORY_SET_VOICE_LOADING =
  'CONTENT_FACTORY_SET_VOICE_LOADING';
export const CONTENT_FACTORY_SET_MARKET_UPDATE_CONTENTS =
  'CONTENT_FACTORY_SET_MARKET_UPDATE_CONTENTS';
export const CONTENT_FACTORY_MARKET_UPDATE_LOADING =
  'CONTENT_FACTORY_MARKET_UPDATE_LOADING';
export const CONTENT_FACTORY_SET_NEWS_CONTENTS =
  'CONTENT_FACTORY_SET_NEWS_CONTENTS';
export const CONTENT_FACTORY_NEWS_LOADING = 'CONTENT_FACTORY_NEWS_LOADING';
export const CONTENT_FACTORY_SET_COMMUNITY_CONTENTS =
  'CONTENT_FACTORY_SET_COMMUNITY_CONTENTS';
export const CONTENT_FACTORY_COMMUNITY_LOADING =
  'CONTENT_FACTORY_COMMUNITY_LOADING';
export const CONTENT_FACTORY_SET_SELECTED_CONTENT =
  'CONTENT_FACTORY_SET_SELECTED_CONTENT';
export const CONTENT_FACTORY_SET_LISTING_CONTENTS =
  'CONTENT_FACTORY_SET_LISTING_CONTENTS';
export const CONTENT_FACTORY_LISTING_LOADING =
  'CONTENT_FACTORY_LISTING_LOADING';
export const CONTENT_FACTORY_SET_BRANDING = 'CONTENT_FACTORY_SET_BRANDING';
export const CONTENT_FACTORY_SET_VIDEO_TEMPLATES =
  'CONTENT_FACTORY_SET_VIDEO_TEMPLATES';
export const CONTENT_FACTORY_SET_VIDEO_TEMPLATES_LOADING =
  'CONTENT_FACTORY_SET_VIDEO_TEMPLATES_LOADING';
export const CONTENT_FACTORY_SET_VIDEO_FONTS =
  'CONTENT_FACTORY_SET_VIDEO_FONTS';
export const CONTENT_FACTORY_SET_FONT = 'CONTENT_FACTORY_SET_FONT';
