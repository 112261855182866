import {
  FAILURE_POST,
  REQUEST_POST,
  SET_AGENT_BRANDING,
  SUCCESS_POST,
  UPDATE_POST,
} from './actionTypes';
import { PostState, ProfileAction } from './types';

export const initialState: PostState = {
  post: null,
  previewPost: null,
  loading: false,
  error: null,
  agentBranding: false,
};

const postReducer = (state = initialState, action: ProfileAction) => {
  const payload = action.payload;
  switch (action.type) {
    case FAILURE_POST:
      return {
        ...state,
        error: payload?.error || null,
        loading: false,
      };

    case SUCCESS_POST:
      return {
        ...state,
        post: payload.isPreview ? state.post : payload?.post,
        previewPost: payload.isPreview ? payload?.post : state.previewPost,
        loading: false,
      };

    case REQUEST_POST:
      return {
        ...state,
        error: null,
        loading: true,
        preview: payload.isPreview ? null : state.previewPost,
      };

    case UPDATE_POST:
      return {
        ...state,
        previewPost: {
          ...state.previewPost,
          title: payload.title,
          description: payload.hashtags,
        },
      };

    case SET_AGENT_BRANDING:
      return {
        ...state,
        agentBranding: payload.agentBranding,
      };

    default:
      return state;
  }
};

export default postReducer;
