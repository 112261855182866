import { VideoSvgOverlayState } from '../../types/video';
import {
  FAILURE_VIDEO_SVG_OVERLAY,
  REQUEST_VIDEO_SVG_OVERLAY,
  SUCCESS_VIDEO_SVG_OVERLAY,
} from './actionTypes';
import { VideoSvgOverlayAction } from './types';

export const initialState: VideoSvgOverlayState = {
  overlays: [],
};

const videoSvgOverlayReducer = (
  state = initialState,
  action: VideoSvgOverlayAction
) => {
  const payload = action.payload;

  const overlayExist = state.overlays.find(
    (overlay) => payload && overlay && overlay.overlayUrl === payload.overlayUrl
  );

  const currOverlay = overlayExist
    ? { ...overlayExist }
    : {
        overlay: '',
        loading: false,
        error: '',
        overlayUrl: '',
      };

  switch (action.type) {
    case FAILURE_VIDEO_SVG_OVERLAY:
      currOverlay.error = payload?.error || '';
      currOverlay.loading = false;
      currOverlay.overlayUrl = payload.overlayUrl;

      break;

    case SUCCESS_VIDEO_SVG_OVERLAY:
      currOverlay.overlayUrl = payload.overlayUrl;
      currOverlay.overlay = payload?.overlay || '';
      currOverlay.loading = false;

      break;

    case REQUEST_VIDEO_SVG_OVERLAY:
      currOverlay.overlayUrl = payload.overlayUrl;
      currOverlay.loading = true;

      break;

    default:
      return state;
  }

  let newOverlays = [];

  if (overlayExist) {
    newOverlays = state.overlays.map((overlay) => {
      if (overlay.overlayUrl === payload.overlayUrl) {
        return currOverlay;
      }

      return overlay;
    });
  } else {
    newOverlays = [...state.overlays, currOverlay];
  }

  return {
    ...state,
    overlays: newOverlays,
  };
};

export default videoSvgOverlayReducer;
