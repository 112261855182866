import axios, { GenericAbortSignal, type AxiosRequestConfig } from 'axios';
import config from '@/config';
import { gtagConfigs } from '@/src/core/constants/gtagConfigs';

const { websiteBaseUrl, apiBaseUrl } = config;

type BaseUrl = 'website-base-url' | 'api-base-url' | 'direct';
const DEFAULT_BASE_URL: BaseUrl = 'api-base-url';
const LOCALHOST = 'http://localhost:3000';
const STAGING_BASE_URL = 'https://staging.roomvu.com';
const DEFAULT_TIMEOUT = 0;

const checkForGtagTimeout = (onTimeout: () => void, threshold = 2) => {
  let tries = 0;
  const gtagTimeoutInterval = setInterval(() => {
    tries += 1;
    if (tries > threshold) {
      clearInterval(gtagTimeoutInterval);
      onTimeout();
    }
  }, 1000);
  return gtagTimeoutInterval;
};

export const getGaClientId = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      if (typeof gtag === 'undefined') {
        resolve('');
        return;
      }
      const gtagTimeoutInterval = checkForGtagTimeout(() => {
        reject('Gtag timeout');
      });
      gtag('get', gtagConfigs.trackerId, 'client_id', async (clientID) => {
        clearInterval(gtagTimeoutInterval);
        resolve(String(clientID));
      });
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getAPIUrl = () => apiBaseUrl;

const getConf = async (timeout: number = 0) => {
  let clientId = '';
  try {
    clientId = await getGaClientId();
  } catch (ex) {}

  return {
    headers: {
      clientId: clientId,
    },
    timeout,
  };
};

function callProxyApi({
  method,
  url,
  params,
  proxyConfigs,
  configs,
}: {
  url: string;
  method: 'get' | 'post' | 'put';
  params: unknown;
  configs?: AxiosRequestConfig;
  proxyConfigs?: AxiosRequestConfig;
}) {
  return axios.post(
    websiteBaseUrl + '/api/proxy',
    {
      method,
      url,
      params,
      configs,
    },
    proxyConfigs
  );
}

function getApiBaseUrl(url: string, baseUrlType: BaseUrl = DEFAULT_BASE_URL) {
  if (baseUrlType === 'api-base-url') {
    return `${apiBaseUrl}${url}`;
  }
  if (baseUrlType === 'website-base-url') {
    let baseUrl = websiteBaseUrl;
    if (websiteBaseUrl === LOCALHOST) {
      baseUrl = STAGING_BASE_URL;
    }
    return `${baseUrl}${url}`;
  }
  return url;
}

function getHeaders(extraConfigHeaders?: AxiosRequestConfig['headers']) {
  let headers = {};
  if (extraConfigHeaders != null) {
    headers = {
      ...headers,
      ...extraConfigHeaders,
    };
  }
  return headers;
}

export async function callPostApi<TData = any>(
  url,
  data,
  options?: {
    baseUrlType?: BaseUrl;
    timeout?: number;
    extraConfig?: AxiosRequestConfig;
  }
) {
  const { baseUrlType, extraConfig } = options ?? {};
  url = getApiBaseUrl(url, baseUrlType);
  return await axios.post<TData>(url, data, {
    ...extraConfig,
    headers: getHeaders(extraConfig?.headers),
    timeout: options?.timeout ?? DEFAULT_TIMEOUT,
  });
}

export async function callPutApi<TData = any>(
  url,
  data,
  options?: {
    baseUrlType?: BaseUrl;
    timeout?: number;
    extraConfig?: AxiosRequestConfig;
  }
) {
  const { baseUrlType, extraConfig } = options ?? {};
  url = getApiBaseUrl(url, baseUrlType);
  return await axios.put<TData>(url, data, {
    ...extraConfig,
    headers: getHeaders(extraConfig?.headers),
    timeout: options?.timeout ?? DEFAULT_TIMEOUT,
  });
}

export async function callGetApi(
  url,
  params = undefined,
  options?: {
    baseUrlType?: BaseUrl;
    timeout?: number;
    extraConfig?: Omit<AxiosRequestConfig, 'params'>;
    signal?: GenericAbortSignal;
  }
) {
  const { baseUrlType, extraConfig } = options ?? {};
  url = getApiBaseUrl(url, baseUrlType);
  if (config.useProxyForApiCall && typeof window !== 'undefined') {
    return await callProxyApi({
      method: 'get',
      url,
      params,
    });
  } else {
    return await axios.get(url, {
      headers: getHeaders(extraConfig?.headers),
      timeout: options?.timeout ?? DEFAULT_TIMEOUT,
      signal: options?.signal,
      params,
    });
  }
}

export async function callDeleteApi(
  url: string,
  axiosConfig: AxiosRequestConfig = null,
  excludeProxy?: boolean
) {
  url = `${apiBaseUrl}${url}`;
  if (config.useProxyForApiCall && typeof window !== 'undefined') {
    if (excludeProxy) {
      return await axios.delete(url, axiosConfig);
    }
    return await axios.delete(websiteBaseUrl + '/api/proxy' + url, axiosConfig);
  } else {
    const conf = await getConf();
    return axios.delete(url, conf);
  }
}

export async function callGetImage(url, params = undefined) {
  const baseUrl =
    config.env === 'development' ? config.imgBaseUrl : websiteBaseUrl;
  url = `${baseUrl}${url}`;
  if (config.useProxyForApiCall && typeof window !== 'undefined') {
    return await callProxyApi({
      method: 'get',
      url,
      params,
    });
  } else {
    const conf = await getConf();
    if (params != undefined) conf['params'] = params;
    return await axios(url, conf);
  }
}

export async function callDirectDownload(
  url,
  fileName,
  mimeType,
  params = undefined
) {
  url = `${apiBaseUrl}${url}`;
  const response = await axios.get(url, {
    method: 'get',
    url,
    params,
    responseType: 'arraybuffer',
  });
  let blob = new Blob([response.data], { type: mimeType });
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}
