import { createSelector } from '@reduxjs/toolkit';
import { DetectDeviceState } from './types';

const selectDetectDevice = (state) => state.detectDevice as DetectDeviceState;

export const selectIsMobile = createSelector(
  selectDetectDevice,
  (detectDeviceState) => detectDeviceState.isMobile
);

export const selectIsMobileOnly = createSelector(
  selectDetectDevice,
  (detectDeviceState) => detectDeviceState.isMobileOnly
);

export const selectIsDesktop = createSelector(
  selectDetectDevice,
  (detectDeviceState) => detectDeviceState.isDesktop
);

export const selectDeviceType = createSelector(
  selectDetectDevice,
  (detectDeviceState) => detectDeviceState.deviceType
);

export const selectIsIOS = createSelector(
  selectDetectDevice,
  (detectDeviceState) => detectDeviceState.isIOS
);
