import {
  CONTENT_FACTORY_COMMUNITY_LOADING,
  CONTENT_FACTORY_FAILED,
  CONTENT_FACTORY_GET_AVAILABLE_CONTENTS,
  CONTENT_FACTORY_LISTING_LOADING,
  CONTENT_FACTORY_MARKET_UPDATE_LOADING,
  CONTENT_FACTORY_NEWS_LOADING,
  CONTENT_FACTORY_SET_AVAILABLE_CONTENTS,
  CONTENT_FACTORY_SET_AVAILABLE_CONTENTS_LOADING,
  CONTENT_FACTORY_SET_BRANDING,
  CONTENT_FACTORY_SET_COMMUNITY_CONTENTS,
  CONTENT_FACTORY_SET_CONTENT_TYPE,
  CONTENT_FACTORY_SET_FONT,
  CONTENT_FACTORY_SET_HAS_VOICE_OVER,
  CONTENT_FACTORY_SET_LISTING_CONTENTS,
  CONTENT_FACTORY_SET_LOADING,
  CONTENT_FACTORY_SET_LOCATION,
  CONTENT_FACTORY_SET_MARKET_UPDATE_CONTENTS,
  CONTENT_FACTORY_SET_NEWS_CONTENTS,
  CONTENT_FACTORY_SET_SELECTED_CONTENT,
  CONTENT_FACTORY_SET_STEP,
  CONTENT_FACTORY_SET_TEMPLATE,
  CONTENT_FACTORY_SET_TEXT_FOR_VOICE,
  CONTENT_FACTORY_SET_VIDEO_FONTS,
  CONTENT_FACTORY_SET_VIDEO_TEMPLATES,
  CONTENT_FACTORY_SET_VIDEO_TEMPLATES_LOADING,
  CONTENT_FACTORY_SET_VOICE,
  CONTENT_FACTORY_SET_VOICE_LOADING,
  CONTENT_FACTORY_SET_ZIPCODES,
} from './actionTypes';
import { ContentFactoryAction, ContentFactoryState } from './types';

export const initialState: ContentFactoryState = {
  contentFactory: {
    location: {
      userRegionName: null,
      userCityName: null,
      userRegionFBKey: null,
    },
    contentType: 'buyer',
    zipcodes: [],
    template: -1,
    font: 1,
    content: {
      hasMarketUpdateContent: false,
      marketUpdateLoading: false,
      marketUpdateContents: [],
      hasNewsContent: false,
      newsLoading: false,
      newsContents: [],
      hasCommunityContent: false,
      communityLoading: false,
      communityContents: [],
      hasListingContent: false,
      listingLoading: false,
      listingContents: [],
    },
    voice: null,
    textForVoice: '',
    voiceLoading: false,
    hasVoiceOver: true,
    branding: {
      name: null,
      name2: null,
      brokerage: null,
      image: '',
      phone: null,
    },
    step: 'user-info',
    selectedContent: {
      type: 'market_update',
      index: -1,
    },
    videoTemplates: [],
    videoFonts: [],
    videoTemplatesLoading: false,
  },
  loading: false,
  error: '',
};

const contentFactoryReducer = (
  state = initialState,
  action: ContentFactoryAction
) => {
  const payload = action.payload;

  switch (action.type) {
    case CONTENT_FACTORY_SET_CONTENT_TYPE:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          contentType: payload.contentFactory.contentType,
        },
      };
    case CONTENT_FACTORY_SET_ZIPCODES:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          zipcodes: payload.contentFactory.zipcodes,
        },
      };
    case CONTENT_FACTORY_SET_LOCATION:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          location: payload.contentFactory.location,
        },
      };
    case CONTENT_FACTORY_GET_AVAILABLE_CONTENTS:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };
    case CONTENT_FACTORY_SET_STEP:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          step: payload.contentFactory.step,
        },
      };
    case CONTENT_FACTORY_SET_TEMPLATE:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          template: payload.contentFactory.template,
        },
      };
    case CONTENT_FACTORY_SET_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case CONTENT_FACTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case CONTENT_FACTORY_SET_AVAILABLE_CONTENTS_LOADING:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          content: {
            ...state.contentFactory.content,
            availableContentLoading:
              payload.contentFactory.content.availableContentLoading,
          },
        },
        loading: false,
      };
    case CONTENT_FACTORY_SET_AVAILABLE_CONTENTS:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          content: {
            ...state.contentFactory.content,
            ...payload.contentFactory.content,
          },
        },
        loading: false,
      };
    case CONTENT_FACTORY_SET_TEXT_FOR_VOICE:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          textForVoice: payload.contentFactory.textForVoice,
        },
      };
    case CONTENT_FACTORY_SET_HAS_VOICE_OVER:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          hasVoiceOver: payload.contentFactory.hasVoiceOver,
        },
      };
    case CONTENT_FACTORY_SET_VOICE_LOADING:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          voiceLoading: payload.contentFactory.voiceLoading,
        },
      };
    case CONTENT_FACTORY_SET_VOICE:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          voice: payload.contentFactory.voice,
        },
      };
    case CONTENT_FACTORY_MARKET_UPDATE_LOADING:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          content: {
            ...state.contentFactory.content,
            marketUpdateLoading:
              payload.contentFactory.content.marketUpdateLoading,
          },
        },
      };
    case CONTENT_FACTORY_SET_MARKET_UPDATE_CONTENTS:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          content: {
            ...state.contentFactory.content,
            marketUpdateContents:
              payload.contentFactory.content.marketUpdateContents,
          },
        },
      };
    case CONTENT_FACTORY_NEWS_LOADING:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          content: {
            ...state.contentFactory.content,
            newsLoading: payload.contentFactory.content.newsLoading,
          },
        },
      };
    case CONTENT_FACTORY_SET_NEWS_CONTENTS:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          content: {
            ...state.contentFactory.content,
            newsContents: payload.contentFactory.content.newsContents,
          },
        },
      };
    case CONTENT_FACTORY_COMMUNITY_LOADING:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          content: {
            ...state.contentFactory.content,
            communityLoading: payload.contentFactory.content.communityLoading,
          },
        },
      };
    case CONTENT_FACTORY_SET_COMMUNITY_CONTENTS:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          content: {
            ...state.contentFactory.content,
            communityContents: payload.contentFactory.content.communityContents,
          },
        },
      };
    case CONTENT_FACTORY_LISTING_LOADING:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          content: {
            ...state.contentFactory.content,
            listingLoading: payload.contentFactory.content.listingLoading,
          },
        },
      };
    case CONTENT_FACTORY_SET_LISTING_CONTENTS:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          content: {
            ...state.contentFactory.content,
            listingContents: payload.contentFactory.content.listingContents,
          },
        },
      };
    case CONTENT_FACTORY_SET_SELECTED_CONTENT:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          selectedContent: payload.contentFactory.selectedContent,
        },
      };
    case CONTENT_FACTORY_SET_BRANDING:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          branding: payload.contentFactory.branding,
        },
      };
    case CONTENT_FACTORY_SET_VIDEO_TEMPLATES:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          videoTemplates: payload.contentFactory.videoTemplates,
        },
      };
    case CONTENT_FACTORY_SET_VIDEO_TEMPLATES_LOADING:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          videoTemplatesLoading: payload.contentFactory.videoTemplatesLoading,
        },
      };
    case CONTENT_FACTORY_SET_VIDEO_FONTS:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          videoFonts: payload.contentFactory.videoFonts,
        },
      };
    case CONTENT_FACTORY_SET_FONT:
      return {
        ...state,
        contentFactory: {
          ...state.contentFactory,
          font: payload.contentFactory.font,
        },
      };
    default:
      return state;
  }
};

export default contentFactoryReducer;
