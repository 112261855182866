import {
  FAILURE_CALCULATE_TOTALS,
  REQUEST_CALCULATE_TOTALS,
  SUCCESS_CALCULATE_TOTALS,
} from './actionTypes';
import { CalculateTotalsAction } from './types';

export const initialState = {
  totals: [],
  loading: false,
  error: '',
};

const calculateTotalReducer = (
  state = initialState,
  action: CalculateTotalsAction
) => {
  const payload = action.payload;

  switch (action.type) {
    case FAILURE_CALCULATE_TOTALS:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };

    case SUCCESS_CALCULATE_TOTALS:
      return {
        ...state,
        totals: payload?.totals,
        loading: false,
      };

    case REQUEST_CALCULATE_TOTALS:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default calculateTotalReducer;
