import { ICRMJourney, ILeadSource } from '@/types/crm';

export const DEFAULT_LEAD_SOURCES: ILeadSource[] = [
  {
    name: 'buyer / facebook ads',
    description: 'buyer leads from facebook ads',
    parent_name: 'facebook ads',
  },
  {
    name: 'seller / facebook ads',
    description: 'seller leads from facebook ads',
    parent_name: 'facebook ads',
  },
  {
    name: 'market update / facebook ads',
    description: 'market update leads from facebook ads',
    parent_name: 'facebook ads',
  },
  {
    name: 'presale / facebook ads',
    description: 'presale leads from facebook ads',
    parent_name: 'facebook ads',
  },
  {
    name: 'recruiting ads / facebook ads',
    description: 'leads from recruiting facebook ads',
    parent_name: 'facebook ads',
  },
];

export const DEFAULT_JOURNEYS: ICRMJourney[] = [
  {
    name: 'Buyer Leads',
    status: 'ACTIVE',
    assigned_leads: 0,
  },
  {
    name: 'Seller Leads',
    status: 'ACTIVE',
    assigned_leads: 0,
  },
  {
    name: 'Market Update',
    status: 'ACTIVE',
    assigned_leads: 0,
  },
  {
    name: 'Presale',
    status: 'ACTIVE',
    assigned_leads: 0,
  },
  {
    name: 'Recruiting Ads',
    status: 'ACTIVE',
    assigned_leads: 0,
  },
];
