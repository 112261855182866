export const REQUEST_GET_SMART_ADS_BASIC_INFO =
  'REQUEST_GET_SMART_ADS_BASIC_INFO';
export const FAILURE_GET_SMART_ADS_BASIC_INFO =
  'FAILURE_GET_SMART_ADS_BASIC_INFO';
export const SUCCESS_GET_SMART_ADS_BASIC_INFO =
  'SUCCESS_GET_SMART_ADS_BASIC_INFO';
export const UPDATE_SMART_ADS_CREATIVE = 'UPDATE_SMART_ADS_CREATIVE';
export const UPDATE_SMART_ADS_USER_INFO = 'UPDATE_SMART_ADS_USER_INFO';
export const UPDATE_SMART_ADS_PAGE_NUMBER = 'UPDATE_SMART_ADS_PAGE_NUMBER';
export const UPDATE_SMART_ADS_SELECTED_PACKAGE =
  'UPDATE_SMART_ADS_SELECTED_PACKAGE';
export const UPDATE_SMART_ADS_MANUAL_INFO = 'UPDATE_SMART_ADS_MANUAL_INFO';
export const UPDATE_SMART_ADS_AUTO_RERUN = 'UPDATE_SMART_ADS_AUTO_RERUN';
export const UPDATE_SMART_ADS_AUTO_LEADS_FOLLOWUP =
  'UPDATE_SMART_ADS_AUTO_LEADS_FOLLOWUP';
export const UPDATE_SMART_ADS_CREATIVE_TEXTS =
  'UPDATE_SMART_ADS_CREATIVE_TEXTS';
export const UPDATE_SMART_ADS_MANUAL_LOCATION_OPTIONS =
  'UPDATE_SMART_ADS_MANUAL_LOCATION_OPTIONS';
export const REQUEST_GET_SMART_ADS_AUDIENCE_SIZE =
  'REQUEST_GET_SMART_ADS_AUDIENCE_SIZE';
export const FAILURE_GET_SMART_ADS_AUDIENCE_SIZE =
  'FAILURE_GET_SMART_ADS_AUDIENCE_SIZE';
export const SUCCESS_GET_SMART_ADS_AUDIENCE_SIZE =
  'SUCCESS_GET_SMART_ADS_AUDIENCE_SIZE';
export const RESET_SMART_ADS_AUDIENCE_SIZE = 'RESET_SMART_ADS_AUDIENCE_SIZE';
export const UPDATE_SMART_ADS_LISTING = 'UPDATE_SMART_ADS_LISTING';
