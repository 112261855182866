import { UpgradePlans } from '@/types/subscription';
import { getUpgradePlanName } from '@/helpers/user-feature';

type ConfigTypes = {
  [key in Exclude<UpgradePlans, UpgradePlans.none>]: {
    key: UpgradePlans;
    name: string;
  };
};

export const SubscriptionPlanConfigs: ConfigTypes = {
  basic: {
    key: UpgradePlans.basic,
    name: getUpgradePlanName(UpgradePlans.basic),
  },
  premium: {
    key: UpgradePlans.premium,
    name: getUpgradePlanName(UpgradePlans.premium),
  },
  platinum: {
    key: UpgradePlans.platinum,
    name: getUpgradePlanName(UpgradePlans.platinum),
  },
  diamond: {
    key: UpgradePlans.diamond,
    name: getUpgradePlanName(UpgradePlans.diamond),
  },
  newsletter: {
    key: UpgradePlans.newsletter,
    name: getUpgradePlanName(UpgradePlans.newsletter),
  },
};
