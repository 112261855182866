import React from 'react';
import { useRouter } from 'next/router';
import { Provider } from 'react-redux';
import dynamic from 'next/dynamic';
import GlobalFont from '@/src/core/components/GlobalFont';
import { wrapper } from '@/redux/store';
import DefaultSeo from '@/src/core/components/DefaultSeo';
import EventContextProvider from '@/helpers/event-context';
import RouterEvents from '@/src/core/components/RouterEvents';
import GtmInitializer from '@/src/core/components/GtmInitializer';
import ReactQueryProvider from '@/src/core/providers/ReactQueryProvider';
import ApiProvider from '@/src/core/providers/ApiProvider';
// Global styles
import '@/styles/antd.scss';
import '@/styles/globals.scss';
import '@/styles/reset.scss';
import '@/src/shared/utils/validationMessages';
import useStylesPreLoader from '@/src/core/hooks/useStylesPreLoader';

const isNpsEnabled = false;
const GdprPopup = dynamic(
  () => import('@/src/containers/GdprPopup/components/GdprPopup')
);
const IntercomChatBot = dynamic(
  () => import('@/src/core/components/IntercomChatBot')
);
const Clarity = dynamic(() => import('@/src/core/components/Clarity'));
const NpsPopupProvider = dynamic(
  () => import('@/src/containers/NpsPopup/components/NpsPopupProvider'),
  { ssr: false }
);

const App = ({ Component, err, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const router = useRouter();
  const {
    isDashboard,
    pageName,
    analyticData,
    dontShowDefaultSeo,
    noGtag,
    withGdpr,
    analyticConfigs,
    noClarity,
    withIntercomChatBot,
  } = Component;

  // This is needed due to style loading issue in nextjs
  useStylesPreLoader();

  return (
    <Provider store={store}>
      <GlobalFont />
      {!dontShowDefaultSeo && <DefaultSeo />}
      {!noGtag && <GtmInitializer isDashboard={isDashboard} />}
      {!noClarity && <Clarity />}
      <ReactQueryProvider dehydratedState={props.pageProps?.dehydratedState}>
        <EventContextProvider pageName={pageName}>
          {withIntercomChatBot && <IntercomChatBot />}
          <RouterEvents
            pageName={pageName}
            analyticData={analyticData}
            analyticConfigs={analyticConfigs}
          />
          <ApiProvider />
          {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
          <Component {...props.pageProps} err={err} />
          {(Boolean(router.query?.gdpr) === true || withGdpr) && <GdprPopup />}
          {isDashboard && isNpsEnabled && (
            <NpsPopupProvider enabled={isNpsEnabled} />
          )}
        </EventContextProvider>
      </ReactQueryProvider>
    </Provider>
  );
};

export default App;
