import { Action } from 'redux';
import { CalendarActions } from './actions';
import { CalendarState } from './types';

export const initialState: CalendarState = {
  period: '14days',
  viewDate: null,
  posts: null,
  calendarUpdating: false,
  loading: false,
  error: null,
  fromEmbedPage: false,
};

type CalendarAction = Action & {
  payload: Partial<CalendarState>;
};

export default function calendarPostsReducer(
  state = initialState,
  action: CalendarAction
) {
  const payload = action.payload;
  switch (action.type) {
    case CalendarActions.FAILURE_CALENDAR_POST:
      return {
        ...state,
        error: payload?.error || null,
        loading: false,
      };

    case CalendarActions.SUCCESS_CALENDAR_POST:
      return {
        ...state,
        posts: payload.posts,
        calendarUpdating: payload.calendarUpdating,
        loading: false,
      };

    case CalendarActions.REQUEST_CALENDAR_POST:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case CalendarActions.SET_CALENDAR_VIEW_DATE:
      return {
        ...state,
        viewDate: action.payload,
      };

    case CalendarActions.SET_FROM_EMBED_PAGE:
      return {
        ...state,
        fromEmbedPage: payload.fromEmbedPage,
      };

    default:
      return state;
  }
}
