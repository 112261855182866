import {
  FAILURE_PROFILE,
  REQUEST_PROFILE,
  SUCCESS_PROFILE,
} from './actionTypes';
import { ProfileAction, ProfileState } from './types';

export const initialState: ProfileState = {
  profile: null,
  loading: false,
  error: '',
};

const profileReducer = (state = initialState, action: ProfileAction) => {
  const payload = action.payload;

  switch (action.type) {
    case FAILURE_PROFILE:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };

    case SUCCESS_PROFILE:
      return {
        ...state,
        profile: payload?.profile,
        loading: false,
      };

    case REQUEST_PROFILE:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default profileReducer;
