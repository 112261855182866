import {
  FAILURE_FILTER_VIDEOS,
  REQUEST_FILTER_VIDEOS,
  SUCCESS_FILTER_NEWS,
  SUCCESS_FILTER_VIDEOS,
} from './actionTypes';
import { TypeAction, TypeState } from './types';

export const initialState: TypeState = {
  videos: null,
  totalCount: 0,
  error: '',
  loading: false,
};

const filterVideoReducer = (state = initialState, action: TypeAction) => {
  const payload = action.payload;

  switch (action.type) {
    case FAILURE_FILTER_VIDEOS:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };

    case SUCCESS_FILTER_VIDEOS:
      return {
        ...state,
        videos: payload?.videos,
        totalCount: payload?.totalCount,
        loading: false,
      };

    case SUCCESS_FILTER_NEWS:
      return {
        ...state,
        news: payload?.news,
        totalCount: payload?.totalCount,
        loading: false,
      };

    case REQUEST_FILTER_VIDEOS:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default filterVideoReducer;
