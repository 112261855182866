import dayjs from '@/src/core/utils/dayjs';

export function createDateFromDay(day: number): Date {
  // Assuming day is a number representing the day of the month
  if (typeof day !== 'number' || day < 1 || day > 31) {
    throw new Error(
      'Invalid day parameter. Please provide a valid day (1-31).'
    );
  }
  return dayjs().startOf('day').add(day, 'day').toDate();
}
