import TagManager from 'react-gtm-module';
import { IUser } from '@/types/user';
import config from '@/config';

interface Args {
  userId: IUser['id'];
  isDashboard: boolean;
}

export const initializeGTM = ({ userId, isDashboard }: Args) => {
  const trackerId =
    (userId == null ? config?.gtm?.anonymousId : config?.gtm?.id) ?? '';
  const tagManagerArgs = {
    gtmId: trackerId,
    dataLayerName: 'dataLayer',
  };

  TagManager.initialize(tagManagerArgs);

  if (isDashboard === true) {
    TagManager.dataLayer({
      dataLayer: {
        userType: 'agent',
      },
    });
  }
  if (userId != null) {
    TagManager.dataLayer({
      dataLayer: {
        userId,
      },
    });
  }
};
