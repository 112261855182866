export default {
  websiteBaseUrl: 'https://kamran.roomvu.com',
  apiBaseUrl: 'https://www.roomvu.com/api',
  dashboardBaseUrl: 'https://www.kamran.roomvu.com/dashboard',
  useProxyForApiCall: true,
  imgBaseUrl: 'https://img.roomvu.com/',
  auth: {
    loginUrl: 'https://kamran.roomvu.com/signin',
    loginApiUrl: '/user/login',
    userAuthenticationUrl: '/user/get',
  },
  googleCaptcha: {
    siteKey: '6LeuuJcUAAAAAJtpfdUpKvjxk62bbz3KCNVfGkuz',
  },
  webEngage: {
    licenseCode: '82617805',
  },
  intrack: {
    IntrackAppKey: 'XXXXXXXXX',
    IntrackAuthKey: 'XXXXXXXXX',
    IntrackPublicKey: 'XXXXXXXXX',
  },
  intercom: {
    IntercomAppID: 'XXXXXXXXX',
  },
  clarity: {
    projectId: 'XXXXXXXXXX',
  },
  sentry: {
    DSN: 'https://0f2b2605c4334568a8e46d435cc7f6f1@sentry.roomvu.com/5',
    environment: 'staging',
  },
};
