import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/redux/user/selectors';
import { initializeGTM } from '@/src/core/utils/gtm';
import { isProduction, isStaging } from '@/src/shared/utils/isProduction';

interface IProps {
  isDashboard: boolean;
}

function GtmInitializer({ isDashboard }: IProps) {
  const userId = useSelector(selectUserInfo)?.id;
  const isInitializedRef = useRef(false);

  useEffect(() => {
    if (!isProduction() && !isStaging()) return;
    if (isInitializedRef.current) return;
    initializeGTM({ userId, isDashboard });
    isInitializedRef.current = true;
  }, []);

  return null;
}

export default React.memo(GtmInitializer);
