import { UPDATE_CAMPAIGN_STATUS } from './actionTypes';
import { CampaignData, EmailMarketingAction } from './types';

export interface EmailMarketingState {
  campaignData: CampaignData;
}

export const initialState: EmailMarketingState = {
  campaignData: null,
};

const emailMarketingReducer = (
  state = initialState,
  action: EmailMarketingAction
) => {
  const payload = action.payload;

  switch (action.type) {
    case UPDATE_CAMPAIGN_STATUS:
      return {
        ...state,
        campaignData: payload.campaignData,
      };
    default:
      return state;
  }
};
export default emailMarketingReducer;
