import {
  FAILURE_USER_REGISTER,
  FAILURE_USER_REGISTER_EMAIL,
  REQUEST_USER_REGISTER,
  SUCCESS_USER_REGISTER,
} from './actionTypes';
import { UserRegisterAction, UserRegisterState } from './types';

export const initialState: UserRegisterState = {
  result: null,
  loading: false,
  error: '',
  error_field: false,
};

const userRegisterReducer = (
  state = initialState,
  action: UserRegisterAction
) => {
  const payload = action.payload;

  switch (action.type) {
    case FAILURE_USER_REGISTER:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };

    case FAILURE_USER_REGISTER_EMAIL:
      return {
        ...state,
        error_field: payload?.error_field,
        error: false,
      };

    case SUCCESS_USER_REGISTER:
      return {
        ...state,
        result: payload?.result,
        loading: false,
      };

    case REQUEST_USER_REGISTER:
      return {
        ...state,
        loading: true,
        error: '',
        error_field: false,
      };

    default:
      return state;
  }
};

export default userRegisterReducer;
