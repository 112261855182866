import { FAILURE_VIDEO, REQUEST_VIDEO, SUCCESS_VIDEO } from './actionTypes';
import type { VideoAction, VideoState } from './types';

export const initialState: VideoState = {
  video: null,
  loading: false,
  error: '',
};

const videoReducer = (state = initialState, action: VideoAction) => {
  const payload = action.payload;

  switch (action.type) {
    case FAILURE_VIDEO:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };

    case SUCCESS_VIDEO:
      return {
        ...state,
        video: payload?.video ?? null,
        loading: false,
      };

    case REQUEST_VIDEO:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default videoReducer;
