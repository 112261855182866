import config from '@/config';

export const COOKIE_KEYS = {
  visitorToken: 'visitor_token',
};

export async function generateVisitorToken() {
  const crypto = await import('crypto');
  return crypto.randomUUID();
}

export function isVisitorTokenExistAndValid(visitorToken: string) {
  return visitorToken != null && visitorToken !== '';
}

export async function getExpireDate() {
  const { default: dayjs } = await import('@/src/core/utils/dayjs');
  return dayjs().startOf('day').add(10, 'year').toDate();
}

export async function getVisitorToken(): Promise<void> {
  try {
    await fetch(`${config.websiteBaseUrl}/api/generate-visitor-token`);
  } catch (err) {
    // ignore the error
  }
}
