export function isEmptyString(value: string | unknown): boolean {
  const isString = typeof value === 'string';
  const isStringButHasNoValue = isString && value?.length < 1;
  return !isString || isStringButHasNoValue;
}

export function removeNonNumericCharacters(str: string) {
  return str.replace(/\D/g, '');
}

export function toCamelCase(input: string): string {
  return input.replace(/\W+(.)/g, (_, char) => char.toUpperCase());
}

export function truncateText(text: string, maxSize: number) {
  if (isEmptyString(text)) {
    return '';
  }
  if (text.length >= maxSize) {
    return text.slice(0, maxSize) + '...';
  }
  return text;
}
