import { FAILURE_TYPES, REQUEST_TYPES, SUCCESS_TYPES } from './actionTypes';
import { TypeAction, TypeState } from './types';

export const initialState: TypeState = {
  types: null,
  error: '',
  loading: false,
};

const typeReducer = (state = initialState, action: TypeAction) => {
  const payload = action.payload;

  switch (action.type) {
    case FAILURE_TYPES:
      return {
        ...state,
        error: payload?.error || '',
        loading: false,
      };

    case SUCCESS_TYPES:
      return {
        ...state,
        types: payload?.types,
        loading: false,
      };

    case REQUEST_TYPES:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

export default typeReducer;
